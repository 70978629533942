import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Button from "~/components/button";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={4}
        lesson={"Lesson 1"}
        color={"ms"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 4</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>During the lessons in this module, your task will be to develop a rubric that people can use to evaluate websites and the information presented on them.</p>
              <p>A <strong>rubric</strong> is a guide that can help you identify what is good, bad, or unclear about the information you find. The rubric lists criteria or evidence that can help people make better decisions.</p>
              <p>In this lesson, you will start working with a rubric that can help people make decisions about websites they visit. In later lessons, you will add to the rubric and then test it before giving it to others to use.</p>
              <p>Click on the link below to get a copy of the rubric. Read over the criteria listed in the first category, "Lesson 1 Website Quality Initial Screen."</p>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="purple"
                  href="/files/ms/lesson_1/MS__S_full_rubric-scl_final.docx"
                  icon="download"
                  title="Lesson 4 rubric"
                >
                  Rubric
                </Button>
              </div>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>In the lessons in this module, students will use a rubric. The rubric is a way to help students focus on evidence as a basis for making decisions. In this lesson, students are given the criteria for evaluating website features. In later lessons, students will be developing the criteria for their rubrics.</p>
              <p>Students are presented with the idea that they are developing a rubric to give to someone else to learn how to evaluate information found on websites. In the field test, students were more engaged in the rubric with this approach compared to developing it for their own use. (Students often thought they did not need to write things down, potentially due to thinking that they are already skilled internet users.)</p>
              <p>Have students access a copy of the rubric. This rubric includes the criteria that students will look for when they examine websites. Allow a few minutes for students to read over the criteria listed in the first section.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
